@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply py-10 px-4 w-full tablet:py-14;
    @include from-tablet-landscape {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include large-desktop {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(3, false, false, 1rem);
        padding-right: offsetWidth(3, false, false, 1rem);
    }
}

.content {
    @include from-tablet {
        padding-left: offsetWidth(1);
        padding-right: offsetWidth(1);
    }

    @include from-tablet-landscape {
        padding-left: offsetWidth(1, 10);
        padding-right: offsetWidth(1, 10);
    }
}

.image {
    width: 1359px;
    height: 690px;
    right: -500px;

    @include from-tablet {
        right: -100px;
        bottom: -100px;
    }

    @include from-tablet-landscape {
        right: auto;
        bottom: -100px;
        left: -500px;
    }
}
