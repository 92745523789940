@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply relative px-12 pt-28 pb-10 tablet:pb-14 tablet:pt-52 tablet-landscape:pt-60;

    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}

.blockWrapperVisual {
    @apply pt-10 tablet:pt-10 tablet-landscape:pt-60;
    @include from-tablet-landscape {
        @apply pr-0;
        width: columnWidth(8)
    }

    @include from-desktop {
        width: columnWidth(7)
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        width: columnWidth(8)
    }
}

.image {
    @include large-desktop {
        padding: 0 offsetWidth(1, 12);
    }
}
