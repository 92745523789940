@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply py-10 px-4 tablet:py-14;
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include from-tablet-landscape {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(3, false, false, 1rem);
        padding-right: offsetWidth(3, false, false, 1rem);
    }

    :global(.slick-arrow) {
        @apply p-4 w-auto h-auto;
        display: none !important;

        &:before {
            display: none;
        }

        @include from-tablet {
            display: block !important;
        }
    }

    :global(.slick-prev) {
        @apply -left-4 -translate-x-full -translate-y-1/2;
    }

    :global(.slick-next) {
        @apply -right-4 translate-x-full -translate-y-1/2;
    }
}

.content {
    @include from-tablet {
        padding-left: offsetWidth(1, 10);
        padding-right: offsetWidth(1, 10);
    }
    @include from-tablet-landscape {
        padding-left: offsetWidth(1, 8);
        padding-right: offsetWidth(1, 8);
    }
    @include large-desktop {
        padding-left: offsetWidth(1, 10);
        padding-right: offsetWidth(1, 10);
    }
}
