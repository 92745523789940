@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply px-12 w-full pb-10 pt-28 tablet:pb-28 tablet:pt-48;
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include from-desktop {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(3, false, false, 1rem);
        padding-right: offsetWidth(3, false, false, 1rem);
    }
}
