@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply px-4 w-full;

    @include from-tablet-landscape {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}
