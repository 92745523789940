.content {
    p {
        @apply text-xs mb-1;
    }
}

.image {
    @apply absolute right-6 top-0;
    width: 39.5rem;
    height: 19rem;
}
