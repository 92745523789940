.menu1 {
    @apply flex flex-col w-full mt-4 tablet-landscape:flex-row tablet-landscape:w-auto tablet-landscape:mt-0;
}

.arrow {
    @apply text-primary tablet-landscape:hidden;
}

.link {
    @apply py-4 flex justify-between items-center;
}
