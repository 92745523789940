@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply pt-28 pb-14 px-4 tablet:pt-36;

    @include from-desktop {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}

.content {
    @include from-tablet {
        padding-left: offsetWidth(1);
        padding-right: offsetWidth(1);
    }

    @include from-tablet {
        padding-left: offsetWidth(1, 12);
        padding-right: offsetWidth(1, 12);
    }

    @include from-desktop {
        padding-left: offsetWidth(1, 10);
        padding-right: offsetWidth(1, 10);
    }

    @include large-desktop {
        padding-left: offsetWidth(1, 12);
        padding-right: offsetWidth(1, 12);
    }
}
