.list {
    @apply mb-4 w-full;
    counter-reset: list;

    li {
        @apply mb-2 pl-8 relative;
        &:last-child {
            @apply mb-0;
        }
    }
}

.ul {
    li {
        &:before {
            @apply w-4 h-px absolute left-0 top-3 bg-orange;
            content: '';
        }
    }
}

.ol {
    li {
        &:before {
            @apply absolute left-0 text-green top-0;
            counter-increment: list;
            content: counter(list) '.';
        }
    }
}
