@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply px-4 py-10 relative tablet:pt-14 tablet-landscape:py-20;

    @include from-tablet-landscape {
        padding-left: offsetWidth(1, false, false, 1rem);
    }

    @include large-desktop {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(3, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }

    &.wide {
        @apply py-5 tablet:pt-7 tablet-landscape:pt-10;
    }
}

.content {
    @apply relative px-8 tablet:pr-16 tablet:pl-28 tablet-landscape:pl-10 tablet-landscape:pr-0;

    &:before {
        @apply absolute left-0 top-0 w-px bg-lightGray tablet:left-16 tablet-landscape:left-0;
        content: '';
        height: calc(100% + 2.5rem);
        @include from-tablet-landscape {
            height: calc(100% + 5rem);
        }
    }
}

.wideContent {
    @apply tablet-landscape:flex tablet-landscape:gap-12 tablet-landscape:justify-between tablet-landscape:items-center tablet-landscape:w-full;
}

.wideImageWrapper {
    @include from-desktop {
        padding-left: offsetWidth(1, 10);
        padding-right: offsetWidth(1, 10);
    }
}

.row {
    @apply tablet-landscape:flex-row-reverse tablet-landscape:items-center tablet-landscape:justify-between;
    .content {
        @apply tablet-landscape:px-0;
        @include from-tablet-landscape {
            width: columnWidth(3, 11);
        }
        &:before {
            @apply tablet-landscape:hidden;
        }
    }
    .image {
        video {
            border-radius: 3.5vw;
        }
        @include from-tablet-landscape {
            width: columnWidth(7, 11);
            video {
                border-radius: 1.8vw;
            }
        }
        @include large-desktop {
            video {
                border-radius: 1.5vw;
            }
        }
        @include fullhd {
            video {
                border-radius: 1.4vw;
            }
        }
    }
}

.backgroundImageTopWrapper {
    @apply absolute top-0 right-0 w-full overflow-hidden;
    height: 110%;

    @include large-desktop {
        height: 120%;
    }
}

.backgroundImageBottomWrapper {
    @apply absolute top-0 right-0 w-full overflow-hidden h-full;
}

.backgroundImage {
    @apply absolute rotate-45;

    width: 44.5rem;
    height: 21.5rem;

    @include from-tablet {
        width: 65rem;
        height: 31.5rem;
    }

    @include from-tablet-landscape {
        height: 47.875rem;
        width: 98.625rem;
    }
}
.positionTop {
    @apply -top-56 right-0;
    @apply tablet:right-1/4;
    @apply tablet-landscape:right-auto tablet-landscape:left-0 tablet-landscape:-translate-y-1/2 tablet-landscape:top-auto tablet-landscape:bottom-0;
    @apply desktop:left-1/4;
    @apply fullhd:left-1/3;
    & > div {
        @apply -translate-x-3/4;
    }

    &.animating {
        & > div {
            @apply translate-x-0 transition-all duration-1000 delay-1000;
        }
    }
}

.positionBottom {
    @apply hidden top-1/2 left-1/2 tablet-landscape:block -translate-y-1/2;
    width: 39.625rem;
    height: 19.25rem;

    & > div {
        @apply opacity-0 translate-x-1/4;
    }

    &.animating {
        & > div {
            @apply opacity-100 translate-x-0 transition-all duration-1000 delay-1000;
            animation: pulse 2s 2s 3;
        }
    }
}

.animationWrapper {
    @apply absolute top-1/2 left-1/2;
    width: 73%;
    transform: translate(-50%, -54%);
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(245, 190, 96, 0.7);
    }

    70% {
        box-shadow: 0 0 0 4rem rgba(245, 190, 96, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(245, 190, 96, 0);
    }
}
