@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
  @apply fixed top-0 left-0 w-full h-full bg-white/60 backdrop-blur-md z-30 overflow-auto flex items-center tablet:px-4;

  @include from-tablet-landscape {
    padding-left: offsetWidth(1, false, false, 1rem);
    padding-right: offsetWidth(1, false, false, 1rem);
  }

  @include large-desktop {
    padding-left: offsetWidth(2, false, false, 1rem);
    padding-right: offsetWidth(2, false, false, 1rem);
  }

  @include fullhd {
    padding-left: offsetWidth(3, false, false, 1rem);
    padding-right: offsetWidth(3, false, false, 1rem);
  }
}

.blockInner {
  @apply relative overflow-hidden py-16 bg-darkGreen text-white text-center px-12 w-full tablet:px-0 tablet:py-20 tablet:rounded-5xl;
  @include from-tablet {
    padding-left: offsetWidth(1, false, false);
    padding-right: offsetWidth(1, false, false);
  }

  @include from-tablet-landscape {
    padding-left: offsetWidth(1, 10, false);
    padding-right: offsetWidth(1, 10, false);
  }
}

.form {
  @apply w-full mt-10;
}

.shape {
  @apply absolute -right-1/2 -top-24 tablet:right-20 tablet:top-0 tablet-landscape:top-auto tablet-landscape:bottom-10;
  width: 86rem;
  height: 44rem;

  & > div {
    @apply opacity-0 -translate-x-1/4 -translate-y-1/4;
  }

  &.animated {
    & > div {
      @apply opacity-100 translate-x-0 translate-y-0 transition-all duration-1000 delay-1000;
    }
  }

  @include from-tablet {
    width: 96.5rem;
    height: 47.5rem;
  }

  @include from-tablet-landscape {
    width: 100rem;
    height: 61.25rem;
  }

  @include from-desktop {
    right: offsetWidth(2);
  }

  @include large-desktop {
    right: offsetWidth(3);
  }

  @include fullhd {
    right: offsetWidth(5);
  }
}
