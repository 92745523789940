.highlighted {
  @apply pt-2;
  background: linear-gradient(270deg, #066536 0%, #0CC367 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlightedOrange {
  @apply pt-2;
  background: linear-gradient(270deg, #EB9A18 0%, #F3B655 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
