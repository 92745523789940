@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.content {
    @apply px-8;

    @include from-tablet {
        padding-left: offsetWidth(1);
        padding-right: offsetWidth(1);
    }

    @include from-desktop {
        padding-left: offsetWidth(1, 10);
        padding-right: offsetWidth(1, 10);
    }

    @include large-desktop {
        padding-left: offsetWidth(2, 12);
        padding-right: offsetWidth(2, 12);
    }
}

.date {
    @apply mb-10;
}
