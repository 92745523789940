@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply py-10 px-0 tablet:py-12;

    :global(.slick-list) {
        @apply pt-5;
    }

    :global(.slick-track) {
        display: flex !important;
    }

    :global(.slick-slide) {
        height: inherit !important;
        & > div {
            height: 100%;
            & > div {
                height: 100%;
            }
        }
    }
}

.scrollWrapper {
    @include from-tablet {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @include mini-fullhd {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}

.content {
    @apply px-12 text-center w-full;
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }
    @include from-tablet-landscape {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}
