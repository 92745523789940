.container {
  @apply relative;
}

.label {
  @apply text-sm text-white text-left z-10 absolute left-12 top-4 uppercase transition-all duration-300;
}

.labelFocused {
  @apply text-xxs normal-case -translate-y-full text-opacity-50;
}

.input {
  @apply bg-transparent border-0 border-b border-solid border-white border-opacity-20 w-full outline-none py-4 px-0;

  &.errorTextarea {
    @apply border-alert;
  }

  &.inputFocused {
    @apply border-green;
  }

  :global(.PhoneInputInput) {
    @apply bg-transparent outline-none;
  }
  :global(.PhoneInputCountry) {
    @apply mr-3;
  }
}

.error {
  @apply text-alert text-xs mt-1 text-left;
}
