@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.icons {
    @apply w-14 h-14;
}

.footer {
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}
