@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply relative px-4 py-10 tablet-landscape:py-20;
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }

    @include large-desktop {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(3, false, false, 1rem);
    }
}

.titleWrapper {
    @apply tablet-landscape:text-right;
    @include from-tablet-landscape {
        width: columnWidth(5, 10)
    }

    @include large-desktop {
        width: columnWidth(5, 11)
    }
}

.benefitsWrapper {
    @apply grid gap-8 mt-10 tablet:gap-12 tablet-landscape:mt-0;
    @include from-tablet-landscape {
        width: columnWidth(4, 10)
    }

    @include large-desktop {
        width: offsetWidth(5, 11)
    }
}

.shapeWrapper {
    @apply hidden absolute rotate-45 top-40 tablet-landscape:block;
    width: 70.625rem;
    height: 34.375rem;
    left: -50rem
}
