.wrapper {
    @apply flex-shrink-0 flex-grow-0 inline-flex relative;
    svg {
        @apply w-full h-auto;
    }
}

.xs {
    @apply w-3 h-3;
}

.sm {
    @apply w-4 h-4;
}

.basic {
    @apply w-6 h-6;
}

.md {
    @apply w-12 h-12;
}

.lg {
    @apply w-20 h-20;
}
