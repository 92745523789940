@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.wrapper {
    @apply tablet:flex tablet:flex-row-reverse tablet:gap-10 tablet:items-center desktop:gap-6 large-desktop:gap-8;

    & > div {
        @apply tablet:flex-1
    }
}
