.heading {
    @apply mx-auto font-bold;
}

.xl {
    @apply text-2xl tablet:text-4xl;
}

.lg {
    @apply text-2xl tablet:text-3xl;
}

.md {
    @apply text-xl tablet:text-3xl;
}

.sm {
    @apply text-base;
}

.xs {
    @apply text-base;
}
