@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply relative pt-28 pb-10 px-4 overflow-hidden tablet:pt-36 tablet:pb-14 tablet-landscape:pb-16 tablet-landscape:pt-64;

    @include from-tablet-landscape {
        @apply pr-0;
        padding-left: offsetWidth(1, false, false, 1rem);
    }

    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
    }
}

.headerWrapper {
    @include from-tablet {
        padding-left: offsetWidth(1);
        padding-right: offsetWidth(1);
    }
    @include from-tablet-landscape {
        @apply px-0;
    }
}

.content {
    @apply px-8 mt-10 w-full;
    @include from-tablet {
        padding-left: offsetWidth(1);
        padding-right: offsetWidth(1);
    }

    @include from-tablet-landscape {
        @apply px-0;
    }
}

.contentWrapper {
    @include from-tablet-landscape {
        width: columnWidth(5, 11, false);
    }
    @include large-desktop {
        width: columnWidth(6, 13, false);
    }
    @include fullhd {
        width: columnWidth(5, 14, false);
    }
}

.wrapper {
    @apply w-full relative;
}

.mapWrapper {
    @apply h-96 tablet-landscape:h-full;
    @include from-tablet-landscape {
        @apply absolute top-0;
        right: offsetWidth(1, 11, false, 1rem);
        width: columnWidth(4, 11, false);
    }
    @include large-desktop {
        right: offsetWidth(1, 13, false, 1rem);
        width: columnWidth(5, 13, false);
    }
    @include fullhd {
        right: offsetWidth(2, 14, false, 1rem);
        width: columnWidth(6, 14, false);
    }
}

.paragraph {
    @apply font-bold text-xl mb-0;
}

.imageWrapper {
    @apply hidden rotate-180 absolute bottom-0 left-1/2 tablet-landscape:block;
    width: 98.625rem;
    height: 47.875rem;
}
