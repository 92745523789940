.container {
    @apply relative;
}

.label {
    @apply text-sm text-white absolute left-0 top-4 uppercase z-10 transform transition-all duration-300 ease-in-out;
}

.labelFocused {
    @apply text-xxs normal-case -translate-y-full text-opacity-50;
}

.input {
    @apply bg-transparent block outline-none w-full py-4 border-b border-solid border-white border-opacity-20 transition-all;
}

.inputFocused {
    @apply border-green;
}

.hasError {
    @apply border-alert;
}

.error {
    @apply mt-1 text-xs text-alert text-left;
}

.iconCommon {
    @apply text-black w-px h-px;
}

.icon {
    composes: iconCommon;
    @apply absolute left-0 top-2;
}

.showPasswordIcon {
    composes: iconCommon;
    @apply absolute top-2 right-0 w-px h-px p-0 bg-transparent outline-none;
}
