.button {
    @apply inline-flex items-center transition-all duration-200 relative no-underline cursor-pointer outline-none border border-solid rounded-4xl py-4 px-10 text-base font-bold justify-center;

    &.hasLink {
        @apply p-0;
    }

    &.disabled {
        @apply cursor-default pointer-events-none;
    }
}

.filled {
    @apply border-primary bg-primary text-white hover:bg-primaryHover;
    &.disabled {
        @apply bg-lightGray border-lightGray;
    }
}

.outline {
    @apply border-primary text-primary hover:bg-secondaryHover;
    &.disabled {
        @apply text-lightGray border-lightGray;
    }

    &.filled {
        @apply bg-white;
    }
}

.outlineWhite {
    @apply border-white text-white hover:bg-white/40;
    &.disabled {
        @apply text-lightGray border-lightGray;
    }

    &.filled {
        @apply hover:bg-white/40;
    }
}

.ghost {
    @apply border-transparent text-primary hover:bg-secondaryHover;
    &.disabled {
        @apply text-lightGray;
    }
}

.link {
    @apply py-4 px-10 whitespace-nowrap;
}

.icon {
    @apply ml-2 mr-0;

    &.iconOnLeft {
        @apply ml-0 mr-2;
    }
}
