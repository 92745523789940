.shape {
  @apply rounded-full absolute w-full h-full;
}

.green {
  @apply bg-green;
}

.red {
  @apply bg-red;
}

.orange {
  @apply bg-orange;
}
