@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply py-10 px-0 tablet:py-20 tablet-landscape:flex-row tablet-landscape:justify-between;

    @include from-tablet-landscape {
        padding-right: offsetWidth(1, false, false, 1rem);
    }
    @include fullhd {
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}

.content {
    @apply px-12 mt-10;
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }
    @include from-tablet-landscape {
        @apply px-0 flex-shrink-0;
        width: columnWidth(5, 11, -1rem);
    }
    @include large-desktop {
        width: columnWidth(5, 13, -1rem);
    }
    @include fullhd {
        width: columnWidth(5, 14, -1rem);
    }
}

.mapWrapper {
    &:before,
    &:after {
        content: '';
        @apply hidden absolute top-0 h-full w-32 z-10 tablet:block;
    }

    &:before {
        @apply left-0;
        background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:after {
        @apply right-0 large-desktop:hidden;
        background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    @include from-tablet-landscape {
        width: columnWidth(5, 11, 1rem);
    }
    @include large-desktop {
        width: columnWidth(8, 14, 1rem);
    }
}
