@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply px-4 pt-36 pb-20 overflow-hidden relative tablet:pt-52 tablet:pb-28 tablet-landscape:flex-row tablet-landscape:justify-between;
    @include from-tablet {
        padding-left: offsetWidth(1, false, false, 1rem);
        padding-right: offsetWidth(1, false, false, 1rem);
    }
    @include fullhd {
        padding-left: offsetWidth(2, false, false, 1rem);
        padding-right: offsetWidth(2, false, false, 1rem);
    }
}

.content {
    @apply relative;
    @include from-tablet-landscape {
        width: columnWidth(6, 10);
    }
    @include large-desktop {
        width: columnWidth(6, 12);
    }
}

.image {
    @apply relative;
    @include from-tablet-landscape {
        width: columnWidth(4, 10);
    }
    @include large-desktop {
        width: columnWidth(5, 12);
    }
}

.descriptionWrapper {
    @apply mt-10;

    @include from-tablet-landscape {
        width: columnWidth(5, 6);
    }
}

.backgroundImage {
    @apply absolute left-10 -bottom-8 tablet:left-4 tablet:-bottom-20 tablet-landscape:bottom-auto tablet-landscape:top-52 desktop:top-36;
    width: 44.5rem;
    height: 21.5rem;

    img {
        @apply rotate-180;
    }

    @include from-tablet {
        width: 74.5rem;
        height: 36.25rem;
    }

    @include from-tablet-landscape {
        width: 98.5rem;
        height: 48rem;
        left: offsetWidth(6, false, false, 1rem);
    }

    @include fullhd {
        left: offsetWidth(7, false, false, 1rem);
    }
}

.screeningParagraph {
    @apply mb-1 text-xs;
}

.detailContent {
    @apply w-full mt-10 tablet:mt-20;
    section {
        @apply mb-10 tablet:mb-20;
    }
}
