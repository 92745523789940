.button {
  @apply inline-flex justify-center items-center transition-all duration-200 relative no-underline cursor-pointer outline-none border border-solid rounded-full w-12 h-12;

  &.hasLink {
    @apply p-0;
  }

  &.disabled {
    @apply cursor-default pointer-events-none opacity-40;
  }
}
