.container {
    @apply relative z-10;
}

.label {
    @apply text-sm text-white text-left z-10 absolute left-0 top-4 uppercase transition-all duration-300;
}

.labelFocused {
    @apply text-xxs normal-case -translate-y-full text-opacity-50;
}

.textarea {
    @apply bg-transparent border-0 border-b border-solid border-white border-opacity-20 block w-full outline-none py-4 px-0;

    &.errorTextarea {
        @apply border-alert;
    }

    &.inputFocused {
        @apply border-green;
    }
}

.error {
    @apply text-alert text-xs absolute -bottom-2 left-0 transform translate-y-full;
}

.icon {
    @apply text-current w-4 h-4;
}
