.container {
    @apply text-left pl-10;
    input {
        visibility: hidden;
        opacity: 0;
        display: none;
    }

    input:checked + .label {
        &:before {
            @apply bg-green border-green;
        }
        &:hover {
            &:before {
                @apply bg-secondaryGreen border-secondaryGreen;
            }
        }

        &:after {
            content: '';
            @apply absolute inline-block rotate-45 h-4 w-2 border-b-2 border-r-2 border-white -left-8 top-0;
        }
    }
}

.label {
    @apply relative cursor-pointer block;
    &:before {
        content: '';
        top: -0.125rem;
        @apply w-6 h-6 absolute -left-10 border border-lightGray rounded transition-all;
    }
    &:hover {
        &:before {
            @apply border-green;
        }
    }
}

.error {
    @apply mt-1 text-xs text-alert text-left;
}
