@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
  @apply py-10 tablet-landscape:py-14;
}

.content {
  @include from-tablet {
    padding-left: offsetWidth(1, false, false, 1rem);
    padding-right: offsetWidth(1, false, false, 1rem);
  }

  @include large-desktop {
    padding-left: offsetWidth(2, false, false, 1rem);
    padding-right: offsetWidth(2, false, false, 1rem);
  }

  @include fullhd {
    padding-left: offsetWidth(3, false, false, 1rem);
    padding-right: offsetWidth(3, false, false, 1rem);
  }
}
