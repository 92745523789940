@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.blockWrapper {
    @apply pt-28 pb-14;
}

.content {
    @apply w-full;
    section {
        @apply mb-10 tablet:mb-20;
    }
}

.wideContent {
    @apply px-4 w-full;

    @include from-desktop {
        padding: 0 offsetWidth(1, false, false, 1rem);
    }
    @include fullhd {
        padding: 0 offsetWidth(2, false, false, 1rem);
    }
}

.mediumContent {
    @apply px-12;

    @include from-tablet {
        padding: 0 offsetWidth(1, false, false, 1rem);
    }
    @include from-desktop {
        padding: 0 offsetWidth(2, false, false, 1rem);
    }
    @include large-desktop {
        padding: 0 offsetWidth(3, false, false, 0.5rem);
    }
    @include fullhd {
        padding: 0 offsetWidth(4, false, false, 0.5rem);
    }
}

.narrowContent {
    @apply px-4 w-full;

    @include from-tablet {
        padding: 0 offsetWidth(1, false, false, 1rem);
    }

    @include large-desktop {
        padding: 0 offsetWidth(3, false, false, 1rem);
    }

    @include fullhd {
        padding: 0 offsetWidth(4, false, false, 1rem);
    }
}
